import AxiosService from "@/core/services/AxiosService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";



@Module
export default class ClienteModule extends VuexModule {
  clientes = {};

  /**
   * Get current Cliente object
   * @returns Cliente
   */
  get getClientes(){
    return this.clientes;
  }
  
 
  
  @Mutation
  [Mutations.SET_CLIENTES](Clientes) {
    this.clientes = Clientes;
  }

  

 
  @Action
  [Actions.GETALL](payload) {

    
    return AxiosService.get("http://127.0.0.1:8000/api/clientes", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENTES, data);
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });
  }

  

}
